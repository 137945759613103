<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h4 class="text-blue-900 text-lg font-semibold">
        Middleware Switching Portal
      </h4>
      <p class="text-sm mt-10">Current Provider: {{ this.defaultProvider }}</p>
      <form @submit.prevent="saveConfig" class="w-1/3">
        <div class="grid grid-cols-1 gap-5 mt-10">
          <div class="flex flex-col pt-4">
            <label for="password" class="text-xs"
              >Middleware Transfer Provider *</label
            >
            <select
              type="text"
              required
              v-model="middlewareProvider"
              class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
            >
              <option :value="null">Select Provider</option>
              <option v-for="(provider, i) in providers" :key="i">
                {{ provider }}
              </option>
            </select>
          </div>
          <div class="flex flex-col pt-4" v-if="middlewareProvider === 'Nibss'">
            <label for="password" class="text-xs">Banks *</label>
            <small class="text-xs text-green-600"
              >Supported Banks includes Polaris, UBA, Fidelity, Access</small
            >
            <select
              type="text"
              required
              v-model="nibssBank"
              class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
            >
              <option :value="null">Select Bank</option>
              <option :value="bank" v-for="(bank, i) in banks" :key="i">
                {{ bank.name }}
              </option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          class="block uppercase shadow bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Save
        </button>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      provider: [],
      middlewareProvider: null,
      nibssBank: null,
      providers: ["Nibss", "Kimono"],
      banks: [],
      defaultProvider: "",
      payload: {
        name: "DEFAULT",
        value: "",
        description: "Default fund transfer provider",
        type: "PROVIDER",
        enabled: true,
      },
    };
  },

  computed: {},
  methods: {
    async saveConfig() {
      if (this.middlewareProvider == "") {
        alert("Select a provider");
        return;
      }
      let res = confirm("Are you sure you want to do this?");
      if (res) {
        this.isLoading = true;

        let payload = {
          route: this.middlewareProvider.toUpperCase(),
        };

        // var myHeaders = new Headers(
        //     content
        // );

        var requestOptions = {
          method: "POST",
          headers: { "content-type": "application/json" },
          redirect: "follow",
          withCredentials: true,
          body: JSON.stringify(payload),
        };

        try {
          this.isLoading = true;

          fetch(
            "http://payment-staging.tractionapps.co:8080/v1/route-strategy/active",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              this.isLoading = false;
              const res = JSON.parse(result);
              if (res.status) {
                alert(res.message);
              }
            })
            .catch((error) => console.log("error", error));
        } catch (error) {}

        if (this.nibssBank) {
          payload = {
            bankCode: this.nibssBank.bank_code,
            bankName: this.nibssBank.name,
          };

          var requestOptions = {
            method: "POST",
            headers: { "content-type": "application/json" },
            redirect: "follow",
            withCredentials: true,
            body: JSON.stringify(payload),
          };

          try {
            this.isLoading = true;

            fetch(
              "http://payment-staging.tractionapps.co:8080/v1/route-strategy/bank/active",
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                this.isLoading = false;
                const res = JSON.parse(result);
                if (res.status) {
                  alert(res.message + "! Bank was added successfully.");
                }
              })
              .catch((error) => console.log("error", error));
          } catch (error) {}
        }
      }
    },
    async fetchActiveProvider() {
      var myHeaders = new Headers();

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        withCredentials: true,
      };

      try {
        this.isLoading = true;

        fetch(
          "http://payment-staging.tractionapps.co:8080/v1/route-strategy/active",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            this.isLoading = false;
            const res = JSON.parse(result);
            this.defaultProvider = res.data;
            console.log(res);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {}
    },
  },
  async created() {
    this.isLoading = true;
    // let providers = await this.$store.dispatch("FETCH_ALL_PROVIDERS");
    // this.providers = providers.data.items;
    this.fetchActiveProvider();

    let bank = await this.$store.dispatch("FETCH_TRACTION_BANK_LISTS");
    this.banks = bank.data.items;
    console.log(this.banks);
    this.isLoading = false;
  },
};
</script>

<style></style>
